import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NavigationState {
  mobile: boolean;
  desktop: boolean;
  activeRoute: string;

  /**admin */
  visible: boolean;
  expandedNodes: string[];
  selectedNodes: string[];
}

const initialState: NavigationState = {
  mobile: false,
  desktop: false,
  activeRoute: "products",

  /**admin */
  visible: false,
  selectedNodes: ["3"],
  expandedNodes: ["2"],
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    showMobile: (state: NavigationState) => {
      state.mobile = true;
      state.desktop = false;
    },
    showDesktop: (state: NavigationState) => {
      state.mobile = false;
      state.desktop = true;
    },
    hideMobile: (state: NavigationState) => {
      state.mobile = false;
    },
    hideDesktop: (state: NavigationState) => {
      state.desktop = false;
    },
    toggleMobile: (state: NavigationState) => {
      state.mobile = !state.mobile;
    },
    toggleDesktop: (state: NavigationState) => {
      state.desktop = !state.desktop;
    },
    setActiveRoute: (state: NavigationState, action: PayloadAction<string>) => {
      state.activeRoute = action.payload;
    },
    /**admin */
    showNavBar: (state: NavigationState) => {
      state.visible = true;
    },
    hideNavBar: (state: NavigationState) => {
      state.visible = false;
    },
    toggleNavBar: (state: NavigationState) => {
      state.visible = !state.visible;
    },
    navBarVisible: (state: NavigationState, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    expandedNavBarNodes: (
      state: NavigationState,
      action: PayloadAction<string[]>
    ) => {
      state.expandedNodes = action.payload;
    },
    selectedNavBarNodes: (
      state: NavigationState,
      action: PayloadAction<string[]>
    ) => {
      state.selectedNodes = action.payload;
    },
    clearNavBarState: (state: NavigationState) => {
      state.selectedNodes = ["3"];
      state.expandedNodes = ["2"];
    },
  },
});

export const {
  showMobile,
  showDesktop,
  hideMobile,
  hideDesktop,
  toggleMobile,
  toggleDesktop,
  setActiveRoute,
  /**admin */
  showNavBar,
  hideNavBar,
  toggleNavBar,
  navBarVisible,
  expandedNavBarNodes,
  selectedNavBarNodes,
  clearNavBarState,
} = navigationSlice.actions;

export default navigationSlice.reducer;
