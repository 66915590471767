import axios from "axios";

import { store } from "./../redux/store";

import { openAlert } from "redux/features/alert/alertSlice";
import { clearAuth } from "redux/features/auth/authSlice";

export const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
});

instanceAxios.interceptors.request.use(
  (request) => {
    if (request.url !== "/api/auth/login") {
      const token = sessionStorage.getItem("token");
      request.headers = {
        ...{ Authorization: `Bearer ` + token },
        Accept: "application/json",
      };
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err: any | []) => {
    const { response } = err;
    const { data } = response;
    const { error } = data;
    const errorArray: string[] = [];
    if (typeof error === "object") {
      for (const property in error) {
        errorArray.push(error[property].join());
      }
    }
    response.status === 401 && store.dispatch(clearAuth());
    store.dispatch(
      openAlert({
        message: errorArray.length ? errorArray : error,
        severity: "error",
        success: false,
      })
    );

    return Promise.reject(error);
  }
);
