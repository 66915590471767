import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Wallet, UserInfo } from "../wallet/walletSlice";
export interface AuthState {
  isFetching: boolean;
  isAuthSkin: boolean;
  isAuthService: boolean;
  services: Service[];
  wallet?: Wallet;
  user?: UserInfo;
  pos?: number[];
  otp: boolean;
  permissions?: string[];
}
export interface Service {
  service: string;
  auth: boolean;
  balance?: number;
}
export interface CurrentUser {
  username: string;
  password: string;
}
export interface ResetPassword {
  username: string;
}
export interface ServiceRequestPayload {
  user: CurrentUser;
  service: string;
  services: Service[];
  i?: number;
}
export interface LoginSkinResponse {
  services: Service[];
  wallet: Wallet;
  user: UserInfo;
  permissions: string[];
}

const initialState: AuthState = {
  isFetching: false,
  isAuthSkin: false,
  isAuthService: false,
  wallet: undefined,
  user: undefined,
  services: [],
  pos: undefined,
  otp: false,
  permissions: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSkinRequested: (
      state: AuthState,
      action: PayloadAction<CurrentUser>
    ) => {
      state.isFetching = true;
      action;
    },
    loginSkinSucceded: (
      state: AuthState,
      action: PayloadAction<LoginSkinResponse>
    ) => {
      const { services, wallet, user, permissions } = action.payload;
      state.isFetching = false;
      state.isAuthSkin = true;
      state.services = services;
      state.wallet = wallet;
      state.user = user;
      state.permissions = permissions;
    },
    loginServiceRequested: (
      state: AuthState,
      action: PayloadAction<ServiceRequestPayload>
    ) => {
      state.isFetching = true;
      action;
    },
    loginServiceSucceded: (state: AuthState) => {
      state.isFetching = false;
      state.otp = false;
    },
    allServicesAuth: (state: AuthState) => {
      state.isAuthService = true;
    },
    savePos: (state: AuthState, action: PayloadAction<number[]>) => {
      state.pos = action.payload;
    },
    saveOtp: (state: AuthState, action: PayloadAction<boolean>) => {
      state.otp = action.payload;
    },
    loginSkinFailed: (state: AuthState) => {
      state.isFetching = false;
      state.isAuthSkin = false;
    },
    loginServiceFailed: (state: AuthState) => {
      state.isFetching = false;
      state.isAuthService = false;
    },
    logoutRequested: (state: AuthState) => {
      state.isFetching = true;
    },
    logoutSucceded: (state: AuthState) => {
      state.isFetching = false;
      state.isAuthSkin = false;
      state.isAuthService = false;
    },
    logoutFailed: (state: AuthState) => {
      state.isAuthSkin = false;
      state.isAuthService = false;
    },
    clearAuth: (state: AuthState) => {
      state.isFetching = false;
      state.isAuthSkin = false;
      state.isAuthService = false;
      state.pos = undefined;
      state.otp = false;
    },
    resetPasswordSucceded: (
      state: AuthState,
      action: PayloadAction<ResetPassword>
    ) => {
      state;
      action;
    },
  },
});

export const {
  loginSkinRequested,
  loginSkinSucceded,
  loginServiceRequested,
  loginServiceSucceded,
  allServicesAuth,
  loginSkinFailed,
  loginServiceFailed,
  logoutRequested,
  logoutSucceded,
  logoutFailed,
  savePos,
  saveOtp,
  clearAuth,
  resetPasswordSucceded,
} = authSlice.actions;

export default authSlice.reducer;
