import { all, call } from "redux-saga/effects";

import { authSagas } from "./features/auth/authSagas";
import { productSagas } from "./features/product/productSagas";
import { configSagas } from "./features/config/configSagas";
import { walletSagas } from "./features/wallet/walletSagas";
import { settingsSagas } from "./features/settings/settingsSagas";

export default function* rootSaga(): Generator<unknown, void, unknown> {
  yield all([
    call(authSagas),
    call(productSagas),
    call(configSagas),
    call(walletSagas),
    call(settingsSagas),
  ]);
}
