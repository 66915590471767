import { instanceAxios } from "services/services";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getSkinRequested,
  getSkinSucceded,
  getSkinFailed,
  getConfigRequested,
  getConfigSucceded,
  getCompanyStatesRequested,
  getCompanyStatesSucceded,
  getCompanyCitiesRequested,
  getCompanyCitiesSucceded,
  getUserStatesRequested,
  getUserStatesSucceded,
  getUserCitiesRequested,
  getUserCitiesSucceded,
  getResidenceStatesRequested,
  getResidenceStatesSucceded,
  getResidenceCitiesRequested,
  getResidenceCitiesSucceded,
  getConfigFailed,
} from "./configSlice";

export function* getSkin(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/config/getskin");
    const { data } = response.data;
    yield put(getSkinSucceded(data));
  } catch (error) {
    yield put(getSkinFailed());
  }
}
export function* getConfig(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/config/getconfig");
    const { data } = response.data;
    yield put(getConfigSucceded(data));
  } catch (error) {
    yield put(getConfigFailed());
  }
}
export function* getCompanyStates({
  payload,
}: ReturnType<typeof getCompanyStatesRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/config/getstates/${payload}`
    );
    const { data } = response.data;
    yield put(getCompanyStatesSucceded(data));
  } catch (error) {
    yield put(getConfigFailed());
  }
}
export function* getCompanyCities({
  payload,
}: ReturnType<typeof getCompanyCitiesRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/config/getcities/${payload}`
    );
    const { data } = response.data;
    yield put(getCompanyCitiesSucceded(data));
  } catch (error) {
    yield put(getConfigFailed());
  }
}
export function* getUserStates({
  payload,
}: ReturnType<typeof getUserStatesRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/config/getstates/${payload}`
    );
    const { data } = response.data;
    yield put(getUserStatesSucceded(data));
  } catch (error) {
    yield put(getConfigFailed());
  }
}
export function* getUserCities({
  payload,
}: ReturnType<typeof getUserCitiesRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/config/getcities/${payload}`
    );
    const { data } = response.data;
    yield put(getUserCitiesSucceded(data));
  } catch (error) {
    yield put(getConfigFailed());
  }
}
export function* getResidenceStates({
  payload,
}: ReturnType<typeof getResidenceStatesRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/config/getstates/${payload}`
    );
    const { data } = response.data;
    yield put(getResidenceStatesSucceded(data));
  } catch (error) {
    yield put(getConfigFailed());
  }
}

export function* getResidenceCities({
  payload,
}: ReturnType<typeof getResidenceCitiesRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/config/getcities/${payload}`
    );
    const { data } = response.data;
    yield put(getResidenceCitiesSucceded(data));
  } catch (error) {
    yield put(getConfigFailed());
  }
}

export function* ongetSkinRequested(): unknown {
  yield takeLatest(getSkinRequested, getSkin);
}
export function* ongetConfigRequested(): unknown {
  yield takeLatest(getConfigRequested, getConfig);
}
export function* ongetCompanyStates(): unknown {
  yield takeLatest(getCompanyStatesRequested, getCompanyStates);
}
export function* ongetCompanyCities(): unknown {
  yield takeLatest(getCompanyCitiesRequested, getCompanyCities);
}
export function* ongetResidenceStates(): unknown {
  yield takeLatest(getResidenceStatesRequested, getResidenceStates);
}
export function* ongetResidenceCities(): unknown {
  yield takeLatest(getResidenceCitiesRequested, getResidenceCities);
}
export function* ongetUserStates(): unknown {
  yield takeLatest(getUserStatesRequested, getUserStates);
}
export function* ongetUserCities(): unknown {
  yield takeLatest(getUserCitiesRequested, getUserCities);
}
export function* configSagas(): unknown {
  yield all([
    call(ongetSkinRequested),
    call(ongetConfigRequested),
    call(ongetCompanyStates),
    call(ongetCompanyCities),
    call(ongetUserStates),
    call(ongetUserCities),
    call(ongetResidenceStates),
    call(ongetResidenceCities),
  ]);
}
