import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoginState {
  service: string;
  index?: number;
  dialog: boolean;
}
interface IPayload {
  service: string;
  index: number;
}

const initialState: LoginState = {
  service: "skin",
  index: 0,
  dialog: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginState: (state: LoginState, action: PayloadAction<IPayload>) => {
      state.service = action.payload.service;
      state.index = action.payload.index;
    },
    clearLoginState: (state: LoginState) => {
      state.service = "skin";
      state.index = 0;
    },
    openDialog: (state: LoginState) => {
      state.dialog = true;
    },
    closeDialog: (state: LoginState) => {
      state.dialog = false;
    },
  },
});

export const { loginState, clearLoginState, openDialog, closeDialog } =
  loginSlice.actions;

export default loginSlice.reducer;
