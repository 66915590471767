import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";
import authReducer from "./features/auth/authSlice";
import navigationReducer from "./features/navigation/navigationSlice";
import productReducer from "./features/product/productSlice";
import configReducer from "./features/config/configSlice";
import walletReducer from "./features/wallet/walletSlice";
import alertReducer from "./features/alert/alertSlice";
import loginReducer from "./features/login/loginSlice";
import settingsReducer from "./features/settings/settingsSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  navigation: navigationReducer,
  product: productReducer,
  config: configReducer,
  wallet: walletReducer,
  alert: alertReducer,
  login: loginReducer,
  settings: settingsReducer,
  i18n: i18nReducer,
});

export default rootReducer;
