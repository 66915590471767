import { instanceAxios } from "services/services";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getServicesRequested,
  getServicesSucceded,
  createServiceRequested,
  createServiceSucceded,
  editServiceRequested,
  editServiceSucceded,
  getProductsRequested,
  getProductsSucceded,
} from "./settingsSlice";
import { productListSucceded } from "../product/productSlice";

export function* listServices(): unknown {
  try {
    const response = yield call(instanceAxios.get, `/api/admin/service/list/`);
    const { data } = response.data;
    yield put(getServicesSucceded(data));
  } catch (error) {
    console.log('error',error);
  }
}
export function* createService({
  payload,
}: ReturnType<typeof createServiceRequested>): unknown {
  try {
    const res = yield call(
      instanceAxios.post,
      `/api/admin/service/create/`,
      payload
    );
    yield put(createServiceSucceded(res.data.data));
  } catch (error) {
    console.log('error',error);
  }
}
export function* editService({
  payload,
}: ReturnType<typeof editServiceRequested>): unknown {
  try {
    const { id, data, index } = payload;
    const res = yield call(
      instanceAxios.put,
      `/api/admin/service/edit/${id}`,
      data
    );
    yield put(
      editServiceSucceded({
        data: res.data.data,
        index: index,
      })
    );
  } catch (error) {
    console.log('error',error);
  }
}

export function* listProduct(): unknown {
  try {
    const response = yield call(instanceAxios.get, `/api/admin/product/list/`);
    const { data } = response.data;
    yield put(getProductsSucceded(data));
    yield put(productListSucceded(data));
  } catch (error) {
    console.log('error',error);
  }
}

export function* onservicesRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getServicesRequested, listServices);
}
export function* onserviceCreate(): Generator<unknown, void, unknown> {
  yield takeLatest(createServiceRequested, createService);
}
export function* onserviceEdit(): Generator<unknown, void, unknown> {
  yield takeLatest(editServiceRequested, editService);
}
export function* onproductsRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getProductsRequested, listProduct);
}
export function* settingsSagas(): Generator<unknown, void, unknown> {
  yield all([
    call(onservicesRequested),
    call(onserviceCreate),
    call(onserviceEdit),
    call(onproductsRequested),
  ]);
}
