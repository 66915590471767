import { instanceAxios } from "services/services";
import { all, call, put, takeLatest, delay } from "redux-saga/effects";
import {
  loginSkinRequested,
  loginSkinSucceded,
  loginServiceRequested,
  loginSkinFailed,
  loginServiceFailed,
  loginServiceSucceded,
  allServicesAuth,
  logoutRequested,
  logoutSucceded,
  logoutFailed,
  savePos,
  saveOtp,
  resetPasswordSucceded,
} from "./authSlice";
import { loginState, clearLoginState } from "../login/loginSlice";
import { openAlert } from "../alert/alertSlice";

export function* loginSkin({
  payload,
}: ReturnType<typeof loginSkinRequested>): unknown {
  try {
    const response = yield call(instanceAxios.post, "/api/auth/login", payload);
    const { data, message } = response.data;
    sessionStorage.setItem("token", response.data.data.access_token);
    yield put(
      loginSkinSucceded({
        services: data.services,
        wallet: data.wallet,
        user: data.user,
        permissions: data.permissions,
      })
    );
    yield put(
      openAlert({
        message: message,
        severity: "success",
        success: false,
      })
    );
    if (data.services) {
      for (const [index, elem] of data.services.entries()) {
        if (!elem.auth && index === 0) {
          yield put(loginState({ service: elem.service, index: index + 1 }));
        }
      }
    } else yield put(allServicesAuth());
  } catch (error) {
    yield put(loginSkinFailed());
  }
}
export function* loginService({
  payload,
}: ReturnType<typeof loginServiceRequested>): unknown {
  try {
    const { user, service, services, i } = payload;
    const response = yield call(
      instanceAxios.post,
      `/api/auth/${service}/login`,
      user
    );
    const { data, message } = response.data;
    const { otp, auth } = data;
    if (otp && !auth) {
      yield put(saveOtp(true));
      let min = 0;
      let otpChecked = false;
      while (min < 180) {
        min += 3;
        const otpResponse = yield call(
          instanceAxios.get,
          "/api/auth/sepafin/otpcheck"
        );
        if (otpResponse.data.data.auth) {
          otpChecked = true;
          yield put(loginServiceSucceded());
          yield put(savePos(otpResponse.data.data.pos));
          yield put(
            openAlert({
              message: message,
              severity: "success",
              success: false,
            })
          );
          break;
        }
        yield delay(5000);
      }
      !otpChecked && (yield put(loginServiceFailed()));
    } else if (auth) {
      yield put(loginServiceSucceded());
      yield put(savePos(data.pos));
      yield put(
        openAlert({
          message: message,
          severity: "success",
          success: false,
        })
      );
    }
    if (services.filter((service) => !service.auth).length === i) {
      yield put(allServicesAuth());
      yield put(clearLoginState());
    }
    for (const [index, elem] of services.entries()) {
      if (!elem.auth && index === i) {
        yield put(loginState({ service: elem.service, index: index + 1 }));
      }
    }
  } catch (error) {
    yield put(loginServiceFailed());
  }
}
export function* resetPass({
  payload,
}: ReturnType<typeof resetPasswordSucceded>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      "/api/auth/sepafin/resetpassword",
      payload
    );
    yield put(
      openAlert({
        message: response.data.message,
        severity: "success",
        success: false,
      })
    );
  } catch (error) {
    console.log("error", error);
  }
}
export function* logout(): Generator<unknown, void, unknown> {
  try {
    yield call(instanceAxios.post, "/api/auth/logout");
    yield put(logoutSucceded());
    sessionStorage.clear();
  } catch (error) {
    yield put(logoutFailed());
  }
}

export function* onLoginSkinRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(loginSkinRequested, loginSkin);
}
export function* onLoginServiceRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(loginServiceRequested, loginService);
}
export function* onLogoutRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(logoutRequested, logout);
}
export function* onResetPassword(): Generator<unknown, void, unknown> {
  yield takeLatest(resetPasswordSucceded, resetPass);
}

export function* authSagas(): Generator<unknown, void, unknown> {
  yield all([
    call(onLoginSkinRequested),
    call(onLoginServiceRequested),
    call(onLogoutRequested),
    call(onResetPassword),
  ]);
}
