import { instanceAxios } from "services/services";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  productListRequested,
  productListSucceded,
  getProductListFailed,
  buyProductRequested,
  buyProductVerify,
  buyProductSucceded,
  buyProductFailed,
  clearVerifiedData,
  checkbillerRequested,
  checkbillerSucceded,
  getCheckbillerFailed,
  addToCartRequested,
  addToCartSucceded,
  addToCartFailed,
  bookCartRequested,
  bookCartSucceded,
  bookCartFailed,
} from "../product/productSlice";
import { openAlert } from "../alert/alertSlice";
import { getReceiptSucceded } from "../wallet/walletSlice";

export function* getProductList(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/product/list");
    const { data } = response.data;
    yield put(productListSucceded(data));
  } catch (error) {
    yield put(getProductListFailed());
  }
}
export function* buyProduct({
  payload,
}: ReturnType<typeof buyProductRequested>): unknown {
  const { id, requestPayload, verify } = payload;
  try {
    const response = yield call(
      instanceAxios.post,
      `api/product/buy/${id}`,
      requestPayload
    );
    const { data, message, success } = response.data;

    if (verify) {
      data.receipt && (yield put(getReceiptSucceded(data.receipt)));
      yield put(buyProductVerify(data));
      data.receipt && (yield put(getReceiptSucceded(data.receipt)));
    } else {
      yield put(buyProductSucceded());
      yield put(
        openAlert({
          message: message,
          severity: "success",
          success: success,
        })
      );
      yield put(getReceiptSucceded(data.receipt));
      yield put(clearVerifiedData());
    }
  } catch (error) {
    yield put(buyProductFailed());
    yield put(clearVerifiedData());
  }
}

export function* check({
  payload,
}: ReturnType<typeof checkbillerRequested>): unknown {
  const { id, requestPayload, auth } = payload;
  try {
    const response = auth
      ? yield call(instanceAxios.post, `api/product/buy/${id}`, requestPayload)
      : yield call(instanceAxios.post, `/api/sepafin/bulletin`, requestPayload);
    const { data, message, success } = response.data;
    yield put(checkbillerSucceded(data.attributes));
    yield put(
      openAlert({
        message: message,
        severity: "success",
        success: success,
      })
    );
  } catch (error) {
    yield put(getCheckbillerFailed());
  }
}
export function* addToCart({
  payload,
}: ReturnType<typeof addToCartRequested>): unknown {
  const { id, requestPayload, auth } = payload;
  try {
    const response = auth
      ? yield call(instanceAxios.post, `api/product/buy/${id}`, requestPayload)
      : yield call(instanceAxios.post, `/api/sepafin/bulletin`, requestPayload);
    const { data, message, success } = response.data;
    yield put(addToCartSucceded(data.attributes));
    yield put(
      openAlert({
        message: message,
        severity: "success",
        success: success,
      })
    );
  } catch (error) {
    yield put(addToCartFailed());
  }
}
export function* bookCart({
  payload,
}: ReturnType<typeof bookCartRequested>): unknown {
  const { id, requestPayload, auth } = payload;
  try {
    const response = auth
      ? yield call(instanceAxios.post, `api/product/buy/${id}`, requestPayload)
      : yield call(instanceAxios.post, `/api/sepafin/bulletin`, requestPayload);
    const { data, message, success } = response.data;
    yield put(bookCartSucceded());
    yield put(
      openAlert({
        message: message,
        severity: "success",
        success: success,
      })
    );
    yield put(getReceiptSucceded(data.receipt));
  } catch (error) {
    yield put(bookCartFailed());
  }
}
export function* onproductListRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(productListRequested, getProductList);
}
export function* onbuyProductRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(buyProductRequested, buyProduct);
}
export function* oncheckbillerRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(checkbillerRequested, check);
}
export function* onaddToCartRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(addToCartRequested, addToCart);
}
export function* onbookCartRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(bookCartRequested, bookCart);
}
export function* productSagas(): Generator<unknown, void, unknown> {
  yield all([
    call(onproductListRequested),
    call(onbuyProductRequested),
    call(oncheckbillerRequested),
    call(onaddToCartRequested),
    call(onbookCartRequested),
  ]);
}
