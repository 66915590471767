import { Service } from "redux/features/settings/settingsSlice";

export const editService = (
  services: Service[],
  data: Service,
  index: number
): Service[] => {
  const newService = {
    ...services[index],
    ...data,
  };
  services[index] = newService;
  return services;
};
