import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransactionType } from "../config/configSlice";
import { FilterElement } from "components/wallet/Filter";
import { convertChildren, editWallet, editUser } from "./wallet.utils";

export interface WalletState {
  isFetching: boolean;
  balances?: Balance[];
  walletTypeId?: number;
  walletId?: number;
  wallet?: Wallet;
  children: Record<string, Wallet[]>;
  newWallet?: PayloadData;
  walletToEdit?: PayloadData;
  users?: UserInfo[];
  skinTransactions?: Transactions;
  networkTransactions?: Transactions;
  receipt?: Receipt;
  priceList: any;
}
export interface Balance {
  service: string;
  balance: number;
}
export interface Wallet {
  id: number;
  name: string;
  overdraf: number;
  balance: number;
  children_overdraft: number;
  children_balance: number;
  children_availability: number;
  children_count: number;
  enabled: boolean;
  currency_id: number;
  currency: Currency;
  parent_id: number;
  availability: number;
  wallet_type?: WalletType;
  address: string;
  city_id: number;
  fee: number;
  total: number;
  vat: string;
  cap: string;
  skin: any;
}
export interface WalletType {
  id?: number;
  label?: string;
  icon?: string;
  multiuser?: number;
  backoffice?: number;
  enabled?: boolean;
  extra_attributes?: number;
  can_contain_wallettype?: number[];
  can_create_wallettype?: number[];
  can_perform_transactiontype?: number[];
  can_contain_wallet?: boolean;
  attributes?: Attributes;
}
export interface Currency {
  id: number;
  label: string;
  enabled: number;
}
export interface Attributes {
  add?: string[];
  edit?: string[];
  wallet: boolean;
}
interface KeysNewData {
  [key: string]:
    | UserInfo
    | WalletInformation
    | CompanyInformation
    | SkinInformation;
}
export interface PayloadData extends KeysNewData {
  user: UserInfo;
  wallet: WalletInformation;
  company: CompanyInformation;
  skin: SkinInformation;
}
export interface UserPayloadData {
  user: UserInfo;
}
export interface WalletInformation {
  wallet_type_id?: number;
  fee?: number;
}
export interface SkinInformation {
  name: string;
  servicegroup_id?: number;
  url: string;
  phone: string;
  email: string;
  banner: string;
  address: string;
  iubenda_policy_id?: number;
  iubenda_site_id?: number;
}
export interface CompanyInformation {
  name: string;
  vat: string;
  cap: string;
  address: string;
  country_id?: number;
  state_id?: number;
  city_id?: number;
}
export interface UserInfo {
  username: string;
  password: string;
  c_password: string;
  email: string;
  fiscal_code: string;
  firstname: string;
  lastname: string;
  gender: string;
  birth_date: Date | null;
  birth_city_id?: number;
  birth_country_id?: number;
  birth_state_id?: number;
  document_type: string;
  document_number: string;
  document_issuer: string;
  document_startdate: Date | null;
  document_enddate: Date | null;
  release_city: string;
  residence_cap: string;
  residence_address: string;
  residence_country_id?: number;
  residence_state_id?: number;
  residence_city_id?: number;
  phone: string;
  enabled?: boolean;
  id?: number;
  wallet_id?: number;
}
export interface Transactions {
  records: TransactionData[];
  recordsFiltered: number;
  recordsTotal: number;
}
export interface TransactionData {
  amount: number;
  attributes?: TrxAttributes;
  created_at: string;
  direct_revenue: boolean;
  revenue: number;
  id: number;
  option: Option;
  option_id: number;
  product: Product;
  product_id: number;
  service: Service;
  service_id: number;
  transaction_type: TransactionType;
  transaction_type_id: number;
  user: UserInfo;
  user_id: number;
  wallet_id: number;
}
export interface TrxAttributes {
  PhoneNumber?: string;
  value?: number;
  IdFormat?: string;
  IdSupplier?: string;
  TotalAmount?: number;
  PlafondEnough?: boolean;
  CheckRequestId?: string;
  CreatedByIpAddress?: string;
}

export interface Option {
  id: number;
  name: string;
}
export interface Product {
  id: number;
  label: string;
}
export interface Service {
  id: number;
  label: string;
}

export interface Receipt {
  content: string;
  filename: string;
}
export interface ResponsePayloadChildren {
  id: number;
  children: Wallet[];
}
export interface RequestPayloadNewWallet {
  id: number;
  data: PayloadData;
  index: number;
}
export interface RequestPayloadEditWallet {
  id: number;
  data: PayloadData;
  index: number;
}
export interface ResponsePayloadWalletToEdit {
  id: number;
  data: PayloadData;
}
export interface ResponsePayloadEditedWallet {
  childId: number;
  parentId: number;
  data: Wallet;
  index: number;
}
export interface RequestPayloadNewUser {
  id: number;
  data: UserPayloadData;
}
export interface RequestPayloadEditUser {
  id: number;
  data: UserPayloadData;
  index: number;
}
export interface ResponsePayloadUser {
  data: UserInfo;
  index: number;
}
export interface RequestPayloadFilter {
  id?: number;
  params?: Record<string, FilterElement[] | number | undefined>;
}
const initialState: WalletState = {
  isFetching: false,
  balances: undefined,
  walletTypeId: undefined,
  wallet: undefined,
  children: {},
  walletToEdit: undefined,
  users: [],
  skinTransactions: undefined,
  networkTransactions: undefined,
  receipt: undefined,
  priceList: undefined,
};
export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    getWalletRequested: (state: WalletState) => {
      state.isFetching = true;
    },
    getWalletSucceded: (state: WalletState, action: PayloadAction<Wallet>) => {
      state.isFetching = false;
      state.wallet = action.payload;
    },
    getWalletFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    getChildrenRequested: (
      state: WalletState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getChildrenSucceded: (
      state: WalletState,
      action: PayloadAction<ResponsePayloadChildren>
    ) => {
      const { children, id } = action.payload;
      state.isFetching = false;
      state.children = {
        ...state.children,
        ...convertChildren(children, id),
      };
    },
    getChildrenFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    createWalletRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadNewWallet>
    ) => {
      state.isFetching = true;
      action;
    },
    createWalletSucceded: (
      state: WalletState,
      action: PayloadAction<ResponsePayloadChildren>
    ) => {
      const { id, children } = action.payload;
      state.isFetching = false;
      state.children[id]
        ? (state.children = {
            ...state.children,
            ...convertChildren(
              [...state.children[id], ...children].reverse(),
              id
            ),
          })
        : (state.children = {
            ...state.children,
            ...convertChildren(children, id),
          });
    },
    createWalletFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    getWalletToEditRequested: (
      state: WalletState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getWalletToEditSucceded: (
      state: WalletState,
      action: PayloadAction<ResponsePayloadWalletToEdit>
    ) => {
      const { data } = action.payload;
      state.isFetching = false;
      state.walletToEdit = data;
    },
    getWalletToEditFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    editWalletRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadEditWallet>
    ) => {
      state.isFetching = true;
      action;
    },
    editWalletSucceded: (
      state: WalletState,
      action: PayloadAction<ResponsePayloadEditedWallet>
    ) => {
      const { childId, parentId, data, index } = action.payload;
      state.isFetching = false;
      state.children[parentId]
        ? (state.children = {
            ...state.children,
            ...editWallet(state.children, parentId, childId, data, index),
          })
        : (state.wallet = data);
    },
    editWalletFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    getUsersRequested: (state: WalletState, action: PayloadAction<number>) => {
      state.isFetching = true;
      action;
    },
    getUsersSucceded: (
      state: WalletState,
      action: PayloadAction<UserInfo[]>
    ) => {
      state.isFetching = false;
      state.users = action.payload;
    },
    getUserFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    createUserRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadNewUser>
    ) => {
      state.isFetching = true;
      action;
    },
    createUserSucceded: (
      state: WalletState,
      action: PayloadAction<UserInfo>
    ) => {
      state.isFetching = false;
      state.users && (state.users = [...state.users, ...[action.payload]]);
    },
    createUserFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    editUserRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadEditUser>
    ) => {
      state.isFetching = true;
      action;
    },
    editUserSucceded: (
      state: WalletState,
      action: PayloadAction<ResponsePayloadUser>
    ) => {
      state.isFetching = false;
      state.users &&
        (state.users = editUser(
          state.users,
          action.payload.data,
          action.payload.index
        ));
    },
    editUserFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    walletTypeId: (state: WalletState, action: PayloadAction<number>) => {
      state.walletTypeId = action.payload;
    },
    walletId: (state: WalletState, action: PayloadAction<number>) => {
      state.walletId = action.payload;
    },
    getSkinTransactionsRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadFilter>
    ) => {
      state.isFetching = true;
      action;
    },
    getSkinTransactionsSucceded: (
      state: WalletState,
      action: PayloadAction<Transactions>
    ) => {
      state.isFetching = false;
      state.skinTransactions = action.payload;
    },
    getSkinTransactionsFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    getNetworkTransactionsRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadFilter>
    ) => {
      state.isFetching = true;
      action;
    },
    getNetworkTransactionsSucceded: (
      state: WalletState,
      action: PayloadAction<Transactions>
    ) => {
      state.isFetching = false;
      state.networkTransactions = action.payload;
    },
    getNetworkTransactionsFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    getReceiptRequested: (
      state: WalletState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = false;
      action;
    },
    getReceiptSucceded: (
      state: WalletState,
      action: PayloadAction<Receipt>
    ) => {
      state.receipt = action.payload;
      state.isFetching = false;
    },
    getReceiptFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    updateBalancesWS: (
      state: WalletState,
      action: PayloadAction<Balance[]>
    ) => {
      state.balances = action.payload;
    },
    clearReceipt: (state: WalletState) => {
      state.receipt = undefined;
    },
    clearWallet: (state: WalletState) => {
      state.wallet = undefined;
      state.children = {};
      state.walletToEdit = undefined;
      state.skinTransactions = undefined;
      state.networkTransactions = undefined;
      state.users = undefined;
    },
    clearWalletTypeId: (state: WalletState) => {
      state.walletTypeId = undefined;
    },
    clearSpecificWallet: (state: WalletState) => {
      state.walletToEdit = undefined;
    },
    clearTransactions: (state: WalletState) => {
      state.skinTransactions = undefined;
      state.networkTransactions = undefined;
    },
    clearUsers: (state: WalletState) => {
      state.users = undefined;
    },
    getPriceListRequested: (
      state: WalletState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getPriceListSucceded: (state: WalletState, action: PayloadAction<any>) => {
      state.isFetching = false;
      state.priceList = action.payload;
    },
    getPriceListFailed: (state: WalletState) => {
      state.isFetching = false;
    },
  },
});
export const {
  getWalletRequested,
  getWalletSucceded,
  getWalletFailed,
  getChildrenRequested,
  getChildrenSucceded,
  getChildrenFailed,
  createWalletRequested,
  createWalletSucceded,
  createWalletFailed,
  getWalletToEditRequested,
  getWalletToEditSucceded,
  getWalletToEditFailed,
  editWalletRequested,
  editWalletSucceded,
  editWalletFailed,
  getUsersRequested,
  getUsersSucceded,
  getUserFailed,
  createUserRequested,
  createUserSucceded,
  createUserFailed,
  editUserRequested,
  editUserSucceded,
  editUserFailed,
  getSkinTransactionsRequested,
  getSkinTransactionsSucceded,
  getSkinTransactionsFailed,
  getNetworkTransactionsRequested,
  getNetworkTransactionsSucceded,
  getNetworkTransactionsFailed,
  walletTypeId,
  getReceiptRequested,
  getReceiptSucceded,
  getReceiptFailed,
  updateBalancesWS,
  clearReceipt,
  clearWallet,
  clearWalletTypeId,
  clearSpecificWallet,
  clearTransactions,
  clearUsers,
  walletId,
  getPriceListRequested,
  getPriceListSucceded,
  getPriceListFailed,
} = walletSlice.actions;

export default walletSlice.reducer;
