import { Wallet, UserInfo } from "./walletSlice";

export const convertChildren = (
  children: Wallet[],
  parentId: number
): Record<string, Wallet[]> => {
  const obj: Record<string, Wallet[]> = {};
  obj[parentId] = children;
  return obj;
};

export const editWallet = (
  children: Record<string, Wallet[]>,
  parentId: number,
  childId: number,
  editedData: Wallet,
  index: number
): Record<string, Wallet[]> => {
  const newData = {
    ...children[parentId][index],
    ...editedData,
  };
  children[parentId].filter(function (value) {
    return value.id !== childId;
  });
  children[parentId][index] = newData;
  return convertChildren(children[parentId], parentId);
};
export const editUser = (
  users: UserInfo[],
  data: UserInfo,
  index: number
): UserInfo[] => {
  const newUser = {
    ...users[index],
    ...data,
  };
  users[index] = newUser;

  return users;
};
