import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface ProductState {
  isFetching: boolean;
  isVerifying: boolean;
  list?: List[];
  verifiedData?: any;
  checkbillerResponse?: CheckBillerResponse;
  addToCartResponse?: AddToCartResponse;
  existingTags?: Record<string, string>;
  product?: List;
  open: boolean;
  categoryMenu: number;
}
export interface List {
  id: number;
  label: string;
  category_id: number;
  enabled: boolean;
  pos: boolean;
  verify: boolean;
  cancel: boolean;
  class: string;
  type: string;
  options: Option[];
}
export interface Option {
  id: number;
  label: string;
  name: string;
  value: number;
  type: string;
  enabled: boolean;
}
export interface PurchaseData {
  action: string;
  option_id?: number;
  attributes?:
    | RechargeAttributes
    | PagoPaAttributes
    | BolloAttributes
    | FrecciaAttributes
    | MavRavAttributes
    | F24Attributes
    | BulletinPayAttributes;
}
export interface RechargeAttributes {
  PhoneNumber?: string;
  value?: number;
}
export interface PagoPaAttributes {
  PAIdentifier: string;
  NoticeNumber: string;
  Amount: string;
  CustomerFee?: number;
  TotalAmount?: number;
  PosCode?: number;
  PaymentType?: number;
  Cause?: string;
}
export interface BolloAttributes {
  TypeOfVehicle?: number;
  LicensePlate?: number;
  Amount?: number;
  CustomerFee?: number;
  TotalAmount?: number;
  PosCode?: number;
  PaymentType?: number;
  Cause?: string;
}
export interface MavRavAttributes {
  PersonType: string;
  FiscalCode: string;
  LastNameOrBusinessName: string;
  FirstName: string;
  Address: string;
  City: string;
  Mobile: string;
  Code: string;
  Cause: string;
  TransferAmount?: number;
  CustomerFee?: number;
  TotalAmount?: number;
  TypesOfBulletin?: number;
  PosCode?: number;
  PaymentType?: number;
}
export interface FrecciaAttributes {
  LastNameOrBusinessName: string;
  FirstName: string;
  FiscalCode: string;
  Address: string;
  City: string;
  PersonType: string;
  Code: string;
  CinAmount?: string;
  CinIdCode?: string;
  CinGlobal?: string;
  Iban: string;
  IdAvoidCode?: number;
  Cause: string;
  TransferAmount?: number;
  PaymentType?: number;
  PosCode?: number;
  TotalAmount?: number;
  CustomerFee?: number;
}
export interface F24Attributes {
  PersonType: string;
  FiscalCode: string;
  officeCode: string;
  deedCode: string;
  LastNameOrBusinessName: string;
  FirstName: string;
  Address: string;
  Cap: string;
  City: string;
  Province: string;
  PayerAgentFiscalCode: string;
  PayerAgentIDCode?: number;
  OperationCode: string;
  taxes: Taxes[];
  documentAmount?: number;
  PaymentType?: number;
  PosCode?: number;
  executionDate: Date | null;
  TotalAmount?: number;
  email?: string;
}
export interface Taxes {
  section: string;
  taxCode: string;
  receiverCode: string;
  isAmendment: boolean;
  isRealEstateVariation: boolean;
  isDeposit: boolean;
  isFinalPayment: boolean;
  realEstateCount?: number;
  referenceMonth: string;
  referenceYear: string;
  deductionAmount?: number;
  taxAmount: number;
  compensationAmount: number;
}
export interface BulletinReserveAttributes {
  UniqueCodeOfCart: string;
  TypeBookingBulletin?: number;
  PayeeCcp?: number;
  TransferAmount?: number;
  BankPaymentSlipCode: string;
  Cause: string;
  CustomerLastNameOrBusinessName: string;
  CustomerFirstName: string;
  CustomerAddress: string;
  CustomerCap: string;
  CustomerCity: string;
  CustomerProvince: string;
  CustomerPersonType: string;
  Denomination: string;
  CustomerFiscalCode: string;
  MetaTags?: Tag[];
  CustomTags?: Tag[];
}
export interface Tag {
  TagLabel: string;
  value: string;
}
export interface BulletinPayAttributes {
  UniqueCodeOfCart: string;
  PaymentType?: number;
  TotalAmount?: number;
  PosCode?: number;
  BookingPaymentsIds?: Array<number>;
}
export interface CheckBillerResponse {
  CustomTags: [];
  MetaTags: [];
  Denomination?: string;
  FiscalCodeOrVatNumberIsRequired: boolean;
  Id: number;
  IsActive: boolean;
  PostalAccount: number;
}
export interface CheckBillerRequestPayload {
  id: number;
  requestPayload: CheckBillerRequest;
  auth: boolean;
}
export interface CheckBillerRequest {
  action: string;
  attributes: CheckBillAttr;
}
export interface CheckBillAttr {
  TypeBookingBulletin?: number;
  PayeeCcp?: number;
}
export interface AddToCartRequestPayload {
  id: number;
  requestPayload: AddToCartRequest;
  auth: boolean;
}
export interface AddToCartRequest {
  action: string;
  attributes: BulletinReserveAttributes;
}
export interface AddToCartResponse {
  BookingCartState: number;
  BookingCartStateValue: string;
  BookingPayments: [];
  TotalAmountOfPayments: number;
  TotalAmountOfPaymentsToPay: number;
  TotalPayments: number;
  TotalPaymentsExpired: number;
  TotalPaymentsPaid: number;
  TotalPaymentsToPay: number;
  UniqueCodeOfCart: string;
}
export interface BookCartRequestPayload {
  id: number;
  requestPayload: BookCartRequest;
  auth: boolean;
}
export interface BookCartRequest {
  action: string;
  attributes: BookCartAttr;
}
export interface BookCartAttr {
  UniqueCodeOfCart: string;
}
export interface RequestPayload {
  id: number;
  requestPayload: PurchaseData;
  action?: string;
  verify?: boolean;
  auth?: boolean;
}

const initialState: ProductState = {
  isFetching: false,
  isVerifying: false,
  list: undefined,
  verifiedData: undefined,
  checkbillerResponse: undefined,
  addToCartResponse: undefined,
  existingTags: undefined,
  product: undefined,
  open: false,
  categoryMenu: 0,
};
export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    productListRequested: (state: ProductState) => {
      state.isFetching = true;
    },
    productListSucceded: (
      state: ProductState,
      action: PayloadAction<List[]>
    ) => {
      state.list = action.payload;
      state.isFetching = false;
    },
    buyProductRequested: (
      state: ProductState,
      action: PayloadAction<RequestPayload>
    ) => {
      state.isFetching = true;
      state.isVerifying = true;
      action;
    },
    buyProductVerify: (state: ProductState, action: PayloadAction<any>) => {
      state.isFetching = false;
      state.verifiedData = action.payload;
      state.isVerifying = false;
    },
    buyProductSucceded: (state: ProductState) => {
      state.isFetching = false;
      state.isVerifying = false;
    },
    getProductListFailed: (state: ProductState) => {
      state.isFetching = false;
    },
    buyProductFailed: (state: ProductState) => {
      state.isFetching = false;
      state.isVerifying = false;
    },
    clearProducts: (state: ProductState) => {
      state.list = undefined;
    },
    clearVerifiedData: (state: ProductState) => {
      state.verifiedData = undefined;
    },
    checkbillerRequested: (
      state: ProductState,
      action: PayloadAction<CheckBillerRequestPayload>
    ) => {
      state.isFetching = true;
      action;
    },
    checkbillerSucceded: (
      state: ProductState,
      action: PayloadAction<CheckBillerResponse>
    ) => {
      state.isFetching = false;
      state.checkbillerResponse = action.payload;
    },
    getCheckbillerFailed: (state: ProductState) => {
      state.isFetching = false;
    },
    addToCartRequested: (
      state: ProductState,
      action: PayloadAction<AddToCartRequestPayload>
    ) => {
      state.isFetching = true;
      action;
    },
    addToCartSucceded: (
      state: ProductState,
      action: PayloadAction<AddToCartResponse>
    ) => {
      state.isFetching = false;
      state.addToCartResponse = action.payload;
      state.checkbillerResponse = undefined;
    },
    addToCartFailed: (state: ProductState) => {
      state.isFetching = false;
    },
    bookCartRequested: (
      state: ProductState,
      action: PayloadAction<BookCartRequestPayload>
    ) => {
      state.isFetching = true;
      action;
    },
    bookCartSucceded: (state: ProductState) => {
      state.isFetching = false;
      state.addToCartResponse = undefined;
      state.checkbillerResponse = undefined;
      state.existingTags = undefined;
    },
    bookCartFailed: (state: ProductState) => {
      state.isFetching = false;
    },
    setExistingTags: (
      state: ProductState,
      action: PayloadAction<Record<string, string>>
    ) => {
      state.existingTags = {
        ...state.existingTags,
        ...action.payload,
      };
    },
    clearBulletin: (state: ProductState, action: PayloadAction<string>) => {
      if (action.payload === "all") {
        state.existingTags = undefined;
        state.checkbillerResponse = undefined;
        state.addToCartResponse = undefined;
      } else {
        state.checkbillerResponse = undefined;
      }
    },
    clickedProduct: (state: ProductState, action: PayloadAction<List>) => {
      state.product = action.payload;
      action;
    },
    clearProduct: (state: ProductState) => {
      state.open = false;
    },
    open: (state: ProductState) => {
      state.open = true;
    },
    close: (state: ProductState) => {
      state.open = false;
    },
    changeCategory: (state: ProductState, action: PayloadAction<number>) => {
      state.categoryMenu = action.payload;
    },
  },
});

export const {
  productListRequested,
  productListSucceded,
  buyProductRequested,
  buyProductVerify,
  buyProductSucceded,
  getProductListFailed,
  buyProductFailed,
  clearProducts,
  clearVerifiedData,
  checkbillerRequested,
  checkbillerSucceded,
  getCheckbillerFailed,
  addToCartRequested,
  addToCartSucceded,
  addToCartFailed,
  bookCartRequested,
  bookCartSucceded,
  bookCartFailed,
  setExistingTags,
  clearBulletin,
  clickedProduct,
  clearProduct,
  open,
  close,
  changeCategory,
} = productSlice.actions;

export default productSlice.reducer;
