import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WalletType } from "../wallet/walletSlice";

export interface ConfigState {
  isFetching: boolean;
  skin?: Skin;
  settings?: Settings;
  categories?: Category[];
  serviceGroups?: ServiceGroup[];
  walletType?: WalletType[];
  transactionType?: TransactionType[];
  countries?: Country[];
  companyStates?: State[];
  companyCities?: City[];
  userStates?: State[];
  userCities?: City[];
  residenceStates?: State[];
  residenceCities?: City[];
}
export interface Skin {
  id: number;
  name: string;
  url: string;
  servicegroup_id: number;
  address: string;
  email: string;
  enabled: boolean;
  phone: string;
  banner: string;
  iubenda_policy_id?: number;
  iubenda_site_id?: number;
}
export interface Settings {
  cashlimit: number;
}
export interface Category {
  id: number;
  label: string;
  name: string;
  type: string;
  enabled: boolean;
  icon: string;
}
export interface ServiceGroup {
  id: number;
  label: string;
  enabled: boolean;
}
export interface TransactionType {
  transaction_id: number;
  user: string;
  service: boolean;
  category: string;
  product: string;
  value: number | string;
  label: string;
}
export interface Country {
  name: string;
  id: number;
  phonecode: string;
  sortname: string;
}
export interface State {
  name?: string;
  id?: number;
}
export interface City {
  name?: string;
  id?: number;
}
export interface Error {
  message: string;
}
export interface ResponsePayload {
  categories: Category[];
  countries?: Country[];
  servicegroups?: ServiceGroup[];
  wallettype: WalletType[];
  transactiontype: TransactionType[];
  settings: Settings;
}
const initialState: ConfigState = {
  isFetching: false,
  skin: undefined,
  settings: undefined,
  categories: undefined,
  serviceGroups: undefined,
  walletType: undefined,
  transactionType: undefined,
  countries: undefined,
  companyStates: undefined,
  companyCities: undefined,
  userStates: undefined,
  userCities: undefined,
  residenceStates: undefined,
  residenceCities: undefined,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    getSkinRequested: (state: ConfigState) => {
      state.isFetching = true;
    },
    getSkinSucceded: (state: ConfigState, action: PayloadAction<Skin>) => {
      state.isFetching = false;
      state.skin = action.payload;
    },
    getSkinFailed: (state: ConfigState) => {
      state.isFetching = false;
    },
    getConfigRequested: (state: ConfigState) => {
      state.isFetching = true;
    },
    getConfigSucceded: (
      state: ConfigState,
      action: PayloadAction<ResponsePayload>
    ) => {
      const {
        categories,
        countries,
        settings,
        transactiontype,
        wallettype,
        servicegroups,
      } = action.payload;
      state.isFetching = false;
      state.categories = categories;
      state.settings = settings;
      state.transactionType = transactiontype;
      state.walletType = wallettype;
      state.countries = countries;
      state.serviceGroups = servicegroups;
    },
    getConfigFailed: (state: ConfigState) => {
      state.isFetching = false;
    },
    getCompanyStatesRequested: (
      state: ConfigState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getCompanyStatesSucceded: (
      state: ConfigState,
      action: PayloadAction<State[] | City[]>
    ) => {
      state.isFetching = false;
      state.companyStates = action.payload;
    },
    getCompanyCitiesRequested: (
      state: ConfigState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getCompanyCitiesSucceded: (
      state: ConfigState,
      action: PayloadAction<State[] | City[]>
    ) => {
      state.isFetching = false;
      state.companyCities = action.payload;
    },
    getUserStatesRequested: (
      state: ConfigState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getUserStatesSucceded: (
      state: ConfigState,
      action: PayloadAction<State[] | City[]>
    ) => {
      state.isFetching = false;
      state.userStates = action.payload;
    },
    getUserCitiesRequested: (
      state: ConfigState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getUserCitiesSucceded: (
      state: ConfigState,
      action: PayloadAction<State[] | City[]>
    ) => {
      state.isFetching = false;
      state.userCities = action.payload;
    },
    getResidenceStatesRequested: (
      state: ConfigState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getResidenceStatesSucceded: (
      state: ConfigState,
      action: PayloadAction<State[] | City[]>
    ) => {
      state.isFetching = false;
      state.residenceStates = action.payload;
    },
    getResidenceCitiesRequested: (
      state: ConfigState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getResidenceCitiesSucceded: (
      state: ConfigState,
      action: PayloadAction<State[] | City[]>
    ) => {
      state.isFetching = false;
      state.residenceCities = action.payload;
    },
    clearConfig: (state: ConfigState) => {
      state.companyStates = undefined;
      state.companyCities = undefined;
      state.userStates = undefined;
      state.userCities = undefined;
      state.residenceStates = undefined;
      state.residenceCities = undefined;
    },
  },
});

export const {
  getSkinRequested,
  getSkinSucceded,
  getSkinFailed,
  getConfigRequested,
  getConfigSucceded,
  getCompanyStatesRequested,
  getCompanyStatesSucceded,
  getCompanyCitiesRequested,
  getCompanyCitiesSucceded,
  getUserStatesRequested,
  getUserStatesSucceded,
  getUserCitiesRequested,
  getUserCitiesSucceded,
  getResidenceStatesRequested,
  getResidenceStatesSucceded,
  getResidenceCitiesRequested,
  getResidenceCitiesSucceded,
  getConfigFailed,
  clearConfig,
} = configSlice.actions;

export default configSlice.reducer;
