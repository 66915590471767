import { instanceAxios } from "services/services";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getWalletRequested,
  getWalletSucceded,
  getChildrenRequested,
  getChildrenSucceded,
  createWalletRequested,
  createWalletSucceded,
  getWalletToEditRequested,
  getWalletToEditSucceded,
  editWalletRequested,
  editWalletSucceded,
  createWalletFailed,
  getUsersRequested,
  getUsersSucceded,
  createUserRequested,
  createUserSucceded,
  editUserRequested,
  editUserSucceded,
  getSkinTransactionsRequested,
  getSkinTransactionsSucceded,
  getNetworkTransactionsRequested,
  getNetworkTransactionsSucceded,
  getReceiptRequested,
  getReceiptSucceded,
  getWalletFailed,
  getChildrenFailed,
  getWalletToEditFailed,
  editWalletFailed,
  getUserFailed,
  createUserFailed,
  editUserFailed,
  getSkinTransactionsFailed,
  getNetworkTransactionsFailed,
  getReceiptFailed,
  getPriceListRequested,
  getPriceListFailed,
  getPriceListSucceded,
} from "./walletSlice";
import { openAlert } from "../alert/alertSlice";

export function* getWallet(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/wallet/get/");
    const { data } = response.data;
    yield put(getWalletSucceded(data));
  } catch (error) {
    yield put(getWalletFailed());
  }
}
export function* getChildren({
  payload,
}: ReturnType<typeof getChildrenRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/children/${payload}`
    );
    const { data } = response.data;
    yield put(
      getChildrenSucceded({
        id: payload,
        children: data,
      })
    );
  } catch (error) {
    yield put(getChildrenFailed());
  }
}
export function* createWallet({
  payload,
}: ReturnType<typeof createWalletRequested>): unknown {
  try {
    const { id, data } = payload;
    const response = yield call(
      instanceAxios.post,
      `/api/wallet/create/${id}`,
      data
    );
    yield put(
      createWalletSucceded({
        id: id,
        children: [response.data.data],
      })
    );
    yield put(
      openAlert({
        message: response.data.message,
        success: response.data.success,
        severity: "success",
      })
    );
  } catch (error) {
    yield put(createWalletFailed());
  }
}
export function* getWalletToEdit({
  payload,
}: ReturnType<typeof getWalletToEditRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/detail/${payload}`
    );
    const { data } = response.data;

    yield put(
      getWalletToEditSucceded({
        id: payload,
        data: data,
      })
    );
  } catch (error) {
    yield put(getWalletToEditFailed());
  }
}
export function* editWallet({
  payload,
}: ReturnType<typeof editWalletRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.put,
      `/api/wallet/edit/${payload.id}`,
      payload.data
    );
    const { data, message, success } = response.data;
    yield put(
      editWalletSucceded({
        childId: payload.id,
        parentId: data.parent_id,
        data: data,
        index: payload.index,
      })
    );
    yield put(
      openAlert({
        message: message,
        success: success,
        severity: "success",
      })
    );
  } catch (error) {
    yield put(editWalletFailed());
  }
}
export function* getUsers({
  payload,
}: ReturnType<typeof getWalletToEditRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/user/list/${payload}`
    );
    const { data } = response.data;

    yield put(getUsersSucceded(data));
  } catch (error) {
    yield put(getUserFailed());
  }
}
export function* createUser({
  payload,
}: ReturnType<typeof createUserRequested>): unknown {
  try {
    const { id, data } = payload;
    const response = yield call(
      instanceAxios.post,
      `/api/wallet/user/create/${id}`,
      data
    );

    yield put(createUserSucceded(response.data.data));
    yield put(
      openAlert({
        message: response.data.message,
        success: response.data.success,
        severity: "success",
      })
    );
  } catch (error) {
    yield put(createUserFailed());
  }
}
export function* editUser({
  payload,
}: ReturnType<typeof editUserRequested>): unknown {
  try {
    const { id, data } = payload;
    const response = yield call(
      instanceAxios.put,
      `/api/wallet/user/edit/${id}`,
      data
    );
    yield put(
      editUserSucceded({ data: response.data.data, index: payload.index })
    );
    yield put(
      openAlert({
        message: response.data.message,
        success: response.data.success,
        severity: "success",
      })
    );
  } catch (error) {
    yield put(editUserFailed());
  }
}
export function* getSkinTransactions({
  payload,
}: ReturnType<typeof getSkinTransactionsRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/transactions/${payload.id}`,
      { params: { params: payload.params } }
    );
    const { data } = response.data;
    yield put(getSkinTransactionsSucceded(data));
  } catch (error) {
    yield put(getSkinTransactionsFailed());
  }
}
export function* getNetworkTransactions({
  payload,
}: ReturnType<typeof getNetworkTransactionsRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/transactions/${payload.id}`,
      { params: { params: payload.params } }
    );
    const { data } = response.data;
    yield put(getNetworkTransactionsSucceded(data));
  } catch (error) {
    yield put(getNetworkTransactionsFailed());
  }
}
export function* getReceipt({
  payload,
}: ReturnType<typeof getReceiptRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `api/transaction/receipt/${payload}`
    );
    const { data } = response.data;

    yield put(getReceiptSucceded(data));
  } catch (error) {
    yield put(getReceiptFailed());
  }
}

export function* getPriceList({
  payload,
}: ReturnType<typeof getPriceListRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/admin/price/list/${payload}`
    );
    const { data } = response.data;
    yield put(getPriceListSucceded(data));
  } catch (err) {
    yield put(getPriceListFailed());
  }
}

export function* ongetWalletRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getWalletRequested, getWallet);
}
export function* ongetChildrenRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getChildrenRequested, getChildren);
}
export function* oncreateWalletRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(createWalletRequested, createWallet);
}
export function* ongetWalletToEditRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getWalletToEditRequested, getWalletToEdit);
}
export function* oneditWalletRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(editWalletRequested, editWallet);
}

export function* ongetUsersRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getUsersRequested, getUsers);
}
export function* oncreateUserRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(createUserRequested, createUser);
}
export function* oneditUserRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(editUserRequested, editUser);
}
export function* ongetSkinTransactionsRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getSkinTransactionsRequested, getSkinTransactions);
}
export function* ongetNetworkTransactionsRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getNetworkTransactionsRequested, getNetworkTransactions);
}
export function* ongetReceiptRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getReceiptRequested, getReceipt);
}

export function* ongetPriceListRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getPriceListRequested, getPriceList);
}
export function* walletSagas(): Generator<unknown, void, unknown> {
  yield all([
    call(ongetWalletRequested),
    call(ongetChildrenRequested),
    call(oncreateWalletRequested),
    call(ongetWalletToEditRequested),
    call(oneditWalletRequested),
    call(ongetUsersRequested),
    call(oncreateUserRequested),
    call(oneditUserRequested),
    call(ongetSkinTransactionsRequested),
    call(ongetNetworkTransactionsRequested),
    call(ongetReceiptRequested),
    call(ongetPriceListRequested),
  ]);
}
