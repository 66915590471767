import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { editService } from "./settings.utils";
import { Category } from "../config/configSlice";

export interface SettingsState {
  isFetching: boolean;
  services?: Service[];
  products?: Product[];
}
export interface Service {
  id?: number;
  label: string;
  auth_type: string;
  enabled: boolean;
  need_authentication: boolean;
  store_credential: boolean;
  direct_access: boolean;
}
export interface EditPayload {
  id?: number;
  data: Service;
  index: number;
}
export interface Product {
  id?: number;
  service_id?: number;
  category_id?: number;
  label: string;
  original_id?: number;
  class: string;
  type: string;
  pos: boolean;
  verify: boolean;
  enabled: boolean;
  cancel: boolean;
  options?: Option[];
  service?: Service;
  category?: Category;
}

export interface Option {
  id?: number;
  enabled: boolean;
  label?: number;
  name: string;
  original_id?: number;
  type?: number;
  value?: number;
}
const initialState: SettingsState = {
  isFetching: false,
  services: [],
  products: [],
};
export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    getServicesRequested: (state: SettingsState) => {
      state.isFetching = true;
    },
    getServicesSucceded: (
      state: SettingsState,
      action: PayloadAction<Service[]>
    ) => {
      state.isFetching = false;
      state.services = action.payload;
    },
    createServiceRequested: (
      state: SettingsState,
      action: PayloadAction<Service>
    ) => {
      state.isFetching = true;
      action;
    },
    createServiceSucceded: (
      state: SettingsState,
      action: PayloadAction<Service>
    ) => {
      state.isFetching = false;
      state.services &&
        (state.services = [...state.services, ...[action.payload]]);
    },
    editServiceRequested: (
      state: SettingsState,
      action: PayloadAction<EditPayload>
    ) => {
      state.isFetching = true;
      action;
    },
    editServiceSucceded: (
      state: SettingsState,
      action: PayloadAction<EditPayload>
    ) => {
      const { data, index } = action.payload;
      state.isFetching = false;
      state.services &&
        (state.services = editService(state.services, data, index));
    },
    getProductsRequested: (state: SettingsState) => {
      state.isFetching = true;
    },
    getProductsSucceded: (
      state: SettingsState,
      action: PayloadAction<Product[]>
    ) => {
      state.isFetching = false;
      state.products = action.payload;
    },
  },
});

export const {
  getServicesRequested,
  getServicesSucceded,
  createServiceRequested,
  createServiceSucceded,
  editServiceRequested,
  editServiceSucceded,
  getProductsRequested,
  getProductsSucceded,
} = settingsSlice.actions;

export default settingsSlice.reducer;
